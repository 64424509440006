$('a:not([href^="tel:"])').on('click tap', function(e) {

  var ref = location.href;
  var url = $(this).attr('href');

  console.log('test');

  if( ref.indexOf(url.replace(/#.*$/, '')) != -1 && url.indexOf('#') != -1 ) {

    var speed = 300;
    var href= url.match(/#.*$/);
    var target = href[0];
    var position = $(target).offset().top - $('.js-header').outerHeight();
    $('body, html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  }
});

$('.js-accrodion-btn').on('click tap', function(e) {

  if($(this).next('.js-accrodion-target').length !== 0) {
    $(this).next('.js-accrodion-target').slideToggle(
      function() {
        $(window).trigger('resize');
      }
    );
  } else {

    $(this).children('.js-accrodion-target').slideToggle(
      function() {
        $(window).trigger('resize');
      }
    );
  }

  $(this).toggleClass('is-active');
  $(window).trigger('resize');
});

$('.js-menu-toggle').on('click tap', function() {
  var status = $('body').attr('data-menu');
  var cat_status = $('.js-hamburger__categories-list').attr('data-category');
  var scrollTop = $(window).scrollTop();

  if( status == 'active' ) {

    var window_scroll_num = $('body').css('top');
    window_scroll_num = window_scroll_num.replace(/[^0-9]/g, '');

    $('body').attr('data-menu', '').css({'top':0});
    $('.js-hamburger').attr('data-under-menu', '');
    
    window.scrollTo(0,Number(window_scroll_num));

    if(cat_status == 'active') {
      $('.js-hamburger__categories-list').attr('data-category','');
    }

  } else {

    $('body').attr('data-menu', 'active').css({'top':-Math.floor(scrollTop)});

    setTimeout(function() {
      $('.js-hamburger').attr('data-under-menu', 'active');
    }, 300);

  }
});

if($('.js-view-box-toggle')[0] && $('.js-view-box')[0]) {

  $('.js-view-box').each(function() {
    if($(this).outerHeight() < 72) {
      $(this).toggleClass('is-active');
      $(this).next('.js-view-box-toggle').hide();
    }
  });

  $('.js-view-box-toggle').on('click tap', function(e) {
    $(this).prev('.js-view-box').toggleClass('is-active');
    $(this).remove();
  });
}


$(window).on('load scroll', function(){
  var window_height = window.innerHeight;
  var scroll_position = $(window).scrollTop();
  var header_height = $('.js-header').outerHeight();

  if(window_height - header_height < scroll_position) {
    $('.js-header').addClass('is-overed');
  
  } else {
    $('.js-header').removeClass('is-overed');

  }
});

$(window).on('load scroll touchmove', function() {

  var page_scrollTop = $(window).scrollTop();

  if( $('.js-pagetop')[0] ) {

    if (page_scrollTop > 1) {
      $('.js-pagetop').addClass('is-active');
    }
    else {
      $('.js-pagetop').removeClass('is-active');
    }
  }

});

$(window).on('load',function(){
  $('.js-pagetop-btn').on('click tap', function() {
    $('body, html').animate({
      scrollTop: 0
    }, 300, 'swing');
  });
})


$(window).on('load resize', function() {

  var headerH = $('.js-header').outerHeight() + 20;

  var header_underH = $('.js-header__nav-categories').outerHeight();

  var window_width = window.innerWidth;

  // detail header
  if( $('.js-detail-header')[0] ) {
    $('.js-detail-header').css({
      'top': headerH,
    });
  }

  // .woocommerce .product .summary
  if( $('.woocommerce .product .summary')[0] ) {
    if(window_width > 1366) {
      $('.woocommerce .product .summary').css({
        'top': headerH,
      });
    } else if(window_width > 1199) {
      $('.woocommerce .product .summary').css({
        'top': headerH + header_underH,
      });

    }
  }

  // .cart_totals
  if( $('.cart_totals')[0] ) {
    $('.cart_totals').css({
      'top': headerH,
    });
  }

  // .woocommerce-checkout-review-order
  if( $('.woocommerce-checkout-review-order')[0] ) {
    $('.woocommerce-checkout-review-order').css({
      'top': headerH,
    });
  }

});

// Lazy Load
$(window).on('load resize scroll', function() {

  // Background
  $('[data-lazy-background]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).css('background-image', 'url('+$(this).data('lazy-background')+')');
      $(this).removeAttr('data-lazy-background');
    }
  });

  // img
  $('[data-lazy-img]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).attr('src', $(this).data('lazy-img'));
      $(this).removeAttr('data-lazy-img');
    }
  });

});


$(window).on('load', function() {

  var window_width = window.innerWidth;

  if( $('.js-detail-header')[0] ) {
    // Sticky Polyfill
    var elem = document.querySelector('.js-detail-header');
    Stickyfill.add(elem);
  }

  if( $('.js-truncate')[0] ) {
    var truncate = Truncator.truncate;
    var truncateElm = document.getElementsByClassName('js-truncate');
    for (var i = 0; i < truncateElm.length; i++) {
      var line = truncateElm[i].dataset.line ? truncateElm[i].dataset.line : 2;
      truncate(truncateElm[i], truncateElm[i].textContent, { line: parseInt(line), ellipsis: '...' });
    }
  }

  // $('.js-select-toggle').on('click tap', function() {
  //   var status = $('body').attr('data-select');
  //   if( status == 'active' ) {
  //     $('body').attr('data-select', '');
  //   } else {
  //     $('body').attr('data-select', 'active');
  //   }
  // });

  $('.js-search-toggle').on('click tap', function() {
    var status = $('body').attr('data-search');
    if( status == 'active' ) {
      $('body').attr('data-search', '');
    } else {
      $('body').attr('data-search', 'active');
    }
  });

  $('.js-cart-toggle').on('click tap', function() {
    var status = $('body').attr('data-cart');
    if( status == 'active' ) {
      $('body').attr('data-cart', '');
    } else {
      $('body').attr('data-cart', 'active');
    }
  });

  if( location.hash && $(location.hash)[0] ) {

    var hash = location.hash;

    setTimeout(function() {
      $('body, html').animate({ scrollTop: $(hash).offset().top - $('.js-header').outerHeight() }, 0, 'linear');
    }, 300 );

  }

  loadFile('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;700&display=swap', 'css');
  loadFile('https://use.fontawesome.com/releases/v5.8.2/css/all.css', 'css');

  $('body').attr('data-status', 'loaded');


  if( $('.js-visual .swiper-slide').length > 1) {

    //MVの文言整形
    $('.js-visual-txt').each(function(){
      var visual_txt = $(this).html();
      visual_txt = visual_txt.replace('<br>','</span><br><span class="p-index__visual-sub-slide-txt-inner">');
      $(this).html(visual_txt);
    });

    var visualSub = new Swiper('.js-visual-sub', {
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },

    });

    var visualMain = new Swiper('.js-visual', {
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      paginationClickable: true,
      pagination: {
        el: '.js-visual-pagination',
        type: 'bullets',
        clickable: true,

      }
    });

    visualMain.controller.control = visualSub;
    visualSub.controller.control = visualMain;
  }

  if( $('.js-indexslider-contents .swiper-slide').length > 1) {

    var slider = new Swiper('.js-indexslider-contents', {
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },

      paginationClickable: true,
      pagination: {
        el: '.js-indexslider-pagination',
        type: 'bullets',
        clickable: true,

      },

    });
  }

  if( $('.woocommerce-product-gallery__image')[0] ) {

    $('.woocommerce-product-gallery__image').each(function() {
      var src = $(this).find('img').attr('data-src');
      $('.js-product-images .swiper-wrapper').append('<li class="swiper-slide"><div style="background-image: url('+src+');"></div></li>');
    });

    if( $('.woocommerce-product-gallery__image')[1] ) {
      var productImages = new Swiper('.js-product-images', {
        loop: true,
        speed: 1000,
        slidesPerView: 'auto',
        loopAdditionalSlides: 1,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.js-product-images-next',
          prevEl: '.js-product-images-prev',
        },
        on: {
          slideChange: function () {
            slideThumbnailActive(this, '.woocommerce-product-gallery__image');
          },
        },
      });

      $('.woocommerce-product-gallery__image').on('click tap', function() {
        var index = $('.woocommerce-product-gallery__image').index(this);
        productImages.slideTo(index);
      });

      slideThumbnailActive(productImages, '.woocommerce-product-gallery__image');
    } else {
      $('.js-product-images-next, .js-product-images-prev').hide();
    }

  }
});


$(window).on('load',function() {
  //リスト毎にmatch_height
  var grid_list = document.getElementsByClassName('c-grid');
  var grid_count = grid_list.length;
  var child_class_name = '.js-item-ttl';

  for(i = 0; i < grid_count; i++) {
    var class_name = grid_list[i].className;

    if(class_name.indexOf('is-') !== -1) {
      class_name = '.' + class_name.match(/is-[0-9a-zA-Z]*/) + ' ' + child_class_name;

      $(class_name).matchHeight();
    }
  }

  $('.js-item-txt').matchHeight();
  $('.js-feature-txt').matchHeight();
  $('.js-blog-post-txt').matchHeight({
    byRow: false,
  });


  if($('.js-visual-post-ttl')[0]) {
    var block_width = 0;

    $('.js-visual-post-ttl').each(function(){
      var this_block_width = $(this).outerWidth();

      if(block_width < this_block_width) {
        block_width = this_block_width;
      }
    });

    $('.js-visual-post-ttl').each(function(){
      $(this).css({
        'width':block_width
      })
    });
  }

})


$(window).on('load scroll',function() {
  var page_scrollTop = $(window).scrollTop();

  if( $('.js-float-bnr')[0] ) {

    if (page_scrollTop > 1) {
      $('.js-float-bnr').addClass('is-active');
    }
    else {
      $('.js-float-bnr').removeClass('is-active');
    }
  }
});

// Swiper 
Swiper.prototype.getBreakpoint = function (breakpoints) {

  var window_size = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

  var swiper = this;
  // Get breakpoint for window width
  if (!breakpoints) {
      return undefined;
  }
  var breakpoint = false;
  var points = [];
  Object.keys(breakpoints).forEach(function (point) {
      points.push(point);
  });
  points.sort(function (a, b) {
      return parseInt(a, 10) - parseInt(b, 10);
  });
  for (var i = 0; i < points.length; i += 1) {
      var point = points[i];
      if (swiper.params.breakpointsInverse) {
          if (point <= window_size) {
              breakpoint = point;
          }
      } else if (point >= window_size && !breakpoint) {
          breakpoint = point;
      }
  }
  return breakpoint || 'max';
};

function slideThumbnailActive(slider, thumbnail) {
  var index = slider.realIndex;
  $(thumbnail).removeClass('is-active');
  $(thumbnail).eq(index).addClass('is-active');
}

function loadFile(src, type) {

  if( type == 'js' ) {
    $('body').append('<script src="'+src+'"></script>');
  }

  else if( type == 'css' ) {
    $('body').append('<link rel="stylesheet" href="'+src+'">');
  }

  else {
    return false;
  }

}